import React from 'react';
import { graphql, useFragment } from 'react-relay';
import Account from '@atlassian/account';
import { isMobileAndInMVPExperiment } from '@atlassian/jira-mobile-web/src/index.tsx';
import { useProfileLink } from '@atlassian/jira-navigation-apps-common/src/utils/index.tsx';
import { useCurrentUser } from '@atlassian/jira-platform-services-user-current/src/main.tsx';
import type { DataBasic } from '@atlassian/jira-platform-services-user-current/src/types.tsx';
import type { Nav4Profile$key } from '@atlassian/jira-relay/src/__generated__/Nav4Profile.graphql';
import { useThemePreference } from '@atlassian/jira-router-resources-theme-preference/src/controllers/resource-erai-1615-old/index.tsx';
import type { User } from '@atlassian/jira-shared-types/src/rest/jira/user.tsx';
import { useIsAnonymous } from '@atlassian/jira-tenant-context-controller/src/components/is-anonymous/index.tsx';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import { getIdentityEnv, getSiteUrl, getWindow } from './utils.tsx';

type Nav4ProfileProps = {
	queryRef: Nav4Profile$key;
};

export const Nav4Profile = ({ queryRef }: Nav4ProfileProps) => {
	const data = useFragment<Nav4Profile$key>(
		graphql`
			fragment Nav4Profile on Query {
				me {
					user {
						picture
					}
				}
			}
		`,
		queryRef,
	);
	const {
		data: { user },
	} = useCurrentUser();
	const { theme, setTheme } = useThemePreference();
	const profileLink = useProfileLink();
	const isAnonymous = useIsAnonymous();
	const { environment } = useTenantContext();
	const isMobileExperience = isMobileAndInMVPExperiment();
	const identityEnv = getIdentityEnv(environment);

	const currentWindow = getWindow();
	const siteUrl = getSiteUrl(currentWindow);

	const isUserType = (maybeUser: DataBasic | User): maybeUser is User =>
		'emailAddress' in maybeUser;

	const emailAddress = isUserType(user) && user.emailAddress ? user.emailAddress : '';

	return (
		<Account
			name={user?.userFullname}
			picture={data?.me?.user?.picture}
			email={emailAddress}
			viewProfileLink={isMobileExperience ? undefined : profileLink}
			onThemeChange={setTheme}
			currentTheme={theme}
			identityEnvironment={identityEnv}
			application="jira"
			continueUrl={siteUrl}
			isLoggedOut={isAnonymous}
		/>
	);
};
